import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Segment,
  Input,
  Button,
  Icon,
  Message,
  Table,
  Grid,
  List,
  Divider,
} from "semantic-ui-react";
import { selectAccountProviders } from "../../selectors";
import { searchForPerson, getAccountsForIdentity } from "../../actions/api";
import styles from "./UsersSearch.module.scss";

const UsersSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [identities, setIdentities] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ content: "", color: "" });

  const dispatch = useDispatch();
  const accountProviders = useSelector(selectAccountProviders);

  const handleSearch = async (term) => {
    setIdentities([]);
    setLoading(true);
    const response = await dispatch(
      searchForPerson(term, ["upn:eq", "personId:eq", "primaryAccountEmail:eq"])
    );
    const results = response.payload || [];

    if (results.length === 0) {
      setMessage({ content: "No users found for your search.", color: "blue" });
    } else if (results.length === 1) {
      setMessage({ content: "User found for your search.", color: "grey" });
      await fetchIdentityAccounts(results);
    } else if (results.length > 1 && isValidSearch(term)) {
      setMessage({ content: "Users found for your search.", color: "grey" });
      await fetchIdentityAccounts(results);
    } else {
      setMessage({
        content: "Please, specify a search that only targets one user.",
        color: "red",
      });
    }
    setLoading(false);
  };

  const isValidSearch = (term) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(term);
  };

  const handleInputChange = (e) => {
    const value = e.target.value.trim();
    setSearchTerm(value);

    if (loading) return;

    if (typingTimeout) clearTimeout(typingTimeout);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (value.length > 3) {
      const timeout = setTimeout(() => {
        if (!loading) {
          handleSearch(value);
        }
      }, 2000);
      setTypingTimeout(timeout);
    }
  };

  async function fetchIdentityAccounts(identities) {
    const identitiesWithAccounts = await Promise.all(
      identities.map(async (identity) => {
        const response = await dispatch(getAccountsForIdentity(identity.id));
        const accounts = response.payload.data;
        return { ...identity, accounts };
      })
    );
    setIdentities(identitiesWithAccounts);
  }

  return (
    <Segment color="blue">
      <Message icon info>
        <Icon name="info circle" />
        <Message.Content>
          Users lookup tool that allows searching users by email, personID, and
          UPN (readable identifier).
        </Message.Content>
      </Message>

      <Input
        placeholder="Search for users by PersonID or email..."
        onChange={handleInputChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            if (typingTimeout) clearTimeout(typingTimeout);
            handleSearch(e.target.value.trim());
          }
        }}
        loading={loading}
        style={{ marginRight: "10px", minWidth: "300px" }}
      />
      <Button
        primary
        onClick={() => {
          if (typingTimeout) clearTimeout(typingTimeout);
          handleSearch(searchTerm);
        }}
        disabled={loading}
      >
        Search
      </Button>

      {message.content && (
        <Message color={message.color} style={{ marginTop: "20px" }}>
          {message.content}
        </Message>
      )}

      {identities.length > 0 && (
        <Grid stackable divided style={{ marginTop: "20px" }}>
          {identities.map((identity, identityIndex) => (
            <React.Fragment key={identityIndex}>
              <Grid.Row>
                <Grid.Column width={4} verticalAlign="middle">
                  <List relaxed>
                    <List.Item
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <List.Icon
                        name="user"
                        size="large"
                        style={{ textAlign: "center" }}
                      />
                      <List.Content>
                        <div>
                          <strong>Account Identifier</strong>
                        </div>
                        <div
                          className={styles.truncatedPropertyText}
                          title={identity.upn}
                        >
                          {identity.upn || "N/A"}
                        </div>
                      </List.Content>
                    </List.Item>
                    <List.Item
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <List.Icon
                        name="id badge"
                        size="large"
                        style={{ textAlign: "center" }}
                      />
                      <List.Content>
                        <div>
                          <strong>Display Name</strong>
                        </div>
                        <div
                          className={styles.truncatedPropertyText}
                          title={identity.displayName}
                        >
                          {identity.displayName || "N/A"}
                        </div>
                      </List.Content>
                    </List.Item>
                    <List.Item
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <List.Icon
                        name="key"
                        size="large"
                        style={{ textAlign: "center" }}
                      />
                      <List.Content>
                        <div>
                          <strong>Person ID</strong>
                        </div>
                        <div>{identity.personId || "Not a CERN account"}</div>
                      </List.Content>
                    </List.Item>
                    <List.Item
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <List.Icon
                        name={identity.blocked ? "close" : "check"}
                        color={identity.blocked ? "red" : "green"}
                        size="large"
                        style={{ textAlign: "center" }}
                      />
                      <List.Content>
                        <div>
                          <strong>Status</strong>
                        </div>
                        <div>{identity.blocked ? "Blocked" : "Active"}</div>
                      </List.Content>
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={12} style={{ width: "100%" }}>
                  {identity.unconfirmed && (
                    <Message warning icon>
                      <Icon name="warning circle" size="small" />
                      <Message.Content>
                        <Message.Header>Placeholder User</Message.Header>
                        This user is a placeholder to reserve group memberships.
                        Once they have logged into CERN, they will be converted
                        to a full user with an associated account. Meanwhile,
                        its credentials (mail and identifier) can't be used by
                        other users.
                      </Message.Content>
                    </Message>
                  )}
                  {identity.accounts?.length > 0 && (
                    <Table basic celled style={{ width: "100%" }}>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell colSpan="4" textAlign="center">
                            Accounts owned by this user
                          </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                          <Table.HeaderCell>Active</Table.HeaderCell>
                          <Table.HeaderCell>
                            Account Identifier
                          </Table.HeaderCell>
                          <Table.HeaderCell>Email</Table.HeaderCell>
                          <Table.HeaderCell>
                            Authentication Provider
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {identity.accounts.map((account, accountIndex) => (
                          <Table.Row key={accountIndex}>
                            <Table.Cell collapsing textAlign="center">
                              {account.blocked ? (
                                <Icon name="close" color="red" />
                              ) : (
                                <Icon name="check" color="green" />
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              <span
                                className={styles.truncatedTableText}
                                title={account.uniqueIdentifier}
                              >
                                {account.uniqueIdentifier}
                              </span>
                            </Table.Cell>
                            <Table.Cell>
                              <span
                                className={styles.truncatedTableText}
                                title={account.emailAddress}
                              >
                                {account.emailAddress}
                              </span>
                            </Table.Cell>
                            <Table.Cell>
                              {accountProviders.find(
                                (provider) =>
                                  provider.id === account.accountProviderId
                              )?.displayName || "N/A"}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  )}
                </Grid.Column>
              </Grid.Row>
              {identityIndex < identities.length - 1 && <Divider section />}
            </React.Fragment>
          ))}
        </Grid>
      )}
    </Segment>
  );
};

export default UsersSearch;
