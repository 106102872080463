import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import { Segment } from "semantic-ui-react";
import Identity from "./identity/Identity";
import AccountDetails from "./accounts/AccountDetails";
import Groups from "./groups/Groups";
import GroupDetails from "./groups/GroupDetails";
import Breadcrumbs from "./Breadcrumbs";
import RequestsWrapper from "./requests/RequestsWrapper";
import RequestDetailsWrapper from "./requests/RequestDetailsWrapper";
import { LoginRequired } from "@authzsvc/common-react-components";
import ConfirmEmail from "./requests/ConfirmEmail";
import {
  getAccountProviders,
  getIdentity,
  getMyRequests,
  getRequestsToApprove,
} from "../actions/api";
import OtherAccountsManagement from "./accountManagement/OtherAccountsManagement";
import AccountEdit from "./accountManagement/AccountEdit";
import GuestAccountRegistration from "./accounts/GuestAccountRegistration";
import GuestAccountRegistrationConfirmation from "./accounts/GuestAccountRegistrationConfirmation";
import { TitleContext } from "../hooks";

const MainRouter = (props) => {
  const {
    authenticated,
    history,
    getAccountProviders,
    getIdentity,
    getMyRequests,
    getRequestsToApprove,
  } = props;

  useEffect(() => {
    getAccountProviders();
    getIdentity();
    getMyRequests();
    getRequestsToApprove();
  }, [getAccountProviders, getIdentity, getMyRequests, getRequestsToApprove]);

  const { title, setCategory, setAction } = React.useContext(TitleContext);

  React.useEffect(() => {
    document.title = title.toString().trim();
  }, [title]);

  return (
    <>
      {authenticated ? (
        <Segment basic>
          <Breadcrumbs history={history} />
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => {
                setCategory("My account");

                return (
                  <Identity
                    {...props}
                    breadcrumbs={[["/", "My Account"]]}
                    active_tab={"a"}
                  />
                );
              }}
            />
            <Route
              path="/users-lookup"
              exact
              render={(props) => {
                setCategory("Users lookup");

                return (
                  <Identity
                    {...props}
                    breadcrumbs={[["/users-lookup", "Users lookup tool"]]}
                    active_tab={"e"}
                  />
                );
              }}
            />
            <Route
              path="/link-orcid/"
              exact
              render={(props) => {
                setCategory("My account");
                setAction("Orcid Link");

                return (
                  <Identity
                    {...props}
                    breadcrumbs={[["/", "My Account"]]}
                    active_tab={"a"}
                  />
                );
              }}
            />
            <Route
              path="/groups/"
              exact
              render={(props) => {
                setCategory("My groups");

                return (
                  <Groups {...props} breadcrumbs={[["/groups", "Groups"]]} />
                );
              }}
            />
            <Route
              path="/groups/:id"
              exact
              render={(props) => {
                const { id } = props.match.params;
                setCategory("My groups");
                setAction(`ID: ${id}`);

                return (
                  <GroupDetails
                    {...props}
                    breadcrumbs={[["/groups", "Groups"]]}
                  />
                );
              }}
            />
            <Route
              path="/accounts"
              exact
              render={(props) => {
                setCategory("My accounts");

                return (
                  <OtherAccountsManagement
                    {...props}
                    breadcrumbs={[["/accounts", "Other Accounts"]]}
                  />
                );
              }}
            />
            <Route
              path="/accounts/:id"
              exact
              render={(props) => {
                const { id } = props.match.params;
                setCategory("My accounts");
                setAction(`ID: ${id}`);

                return (
                  <AccountDetails
                    {...props}
                    breadcrumbs={[["/", "My Account"]]}
                  />
                );
              }}
            />
            <Route
              path="/account-linking/"
              exact
              render={(props) => {
                setCategory("Account linking");

                return (
                  <Identity
                    {...props}
                    active_tab={"c"}
                    breadcrumbs={[["/", "My Account"]]}
                  />
                );
              }}
            />
            <Route
              path="/requests"
              exact
              render={(props) => {
                setCategory("My requests");

                return (
                  <RequestsWrapper
                    {...props}
                    breadcrumbs={[["/requests", "Requests"]]}
                  />
                );
              }}
            />
            <Route
              path="/requests/:id"
              render={(props) => {
                const { id } = props.match.params;
                setCategory("My requests");
                setAction(`ID: ${id}`);

                return (
                  <RequestDetailsWrapper
                    {...props}
                    breadcrumbs={[
                      ["/requests", "Requests"],
                      [`/requests/${props.match.params.id}`, "Current request"],
                    ]}
                  />
                );
              }}
            />
            <Route
              path="/identities/:id"
              exact
              render={(props) => {
                const { id } = props.match.params;
                setCategory("My accounts");
                setAction(`ID: ${id}`);

                return (
                  <AccountEdit
                    {...props}
                    accountManagementOn={true}
                    breadcrumbs={[
                      ["/accounts", "Other Accounts"],
                      [`/`, "Selected account"],
                    ]}
                  />
                );
              }}
            />
            <Route
              path="/confirm-email/:id/:token"
              render={(props) => {
                const { id } = props.match.params;
                setCategory("Email confirmation");
                setAction(`ID: ${id}`);

                return (
                  <ConfirmEmail
                    {...props}
                    breadcrumbs={[["/confirm-email", "Confirm email"]]}
                  />
                );
              }}
            />
            <Route
              path="/guest-registration/complete"
              exact
              render={(props) => {
                setCategory("Guest registration confirmation");

                return <GuestAccountRegistrationConfirmation {...props} />;
              }}
            />
            <Route
              path="/guest-registration"
              exact
              render={(props) => {
                setCategory("Guest registration");

                return <GuestAccountRegistration {...props} />;
              }}
            />
          </Switch>
        </Segment>
      ) : (
        <LoginRequired />
      )}
    </>
  );
};

export default withRouter(
  connect(
    (state) => ({
      authenticated: state.keycloak.authenticated,
    }),
    {
      getAccountProviders,
      getIdentity,
      getRequestsToApprove,
      getMyRequests,
    }
  )(MainRouter)
);
