import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  updateMailProperties,
  searchMailPropertiesIdentifier,
} from "../../../actions/api";
import { notification } from "antd";
import { errorAction, successAction, types as at } from "@authzsvc/api-lib";
import { withRouter } from "react-router";
import { withApiCapabilities } from "../../../wrappers";
import { MailAliasCard } from "@authzsvc/common-react-components";
import { showErrorNotification } from "../../../utils";

const MailAlias = ({
  // props
  mail,
  setMail,
  identity,
  accountManagementOn,
  // actions
  updateMailProperties,
  searchMailPropertiesIdentifier,
}) => {
  const submitMailPropertiesChange = async (data) => {
    console.log("Updating aliases", data);
    const emailResult = await updateMailProperties(identity.id, data);
    if (emailResult.type === successAction(at.UPDATE_MAIL_PROPERTIES)) {
      notification["success"]({
        message: "Aliases updated successfully!",
      });
      setMail(emailResult.payload.data);
    } else if (emailResult.type === errorAction(at.UPDATE_MAIL_PROPERTIES)) {
      showErrorNotification(
        emailResult.payload.title,
        emailResult.payload.data.message
      );
    }
    return emailResult;
  };

  const searchIdentifier = async (type, mail) => {
    return await searchMailPropertiesIdentifier(type, mail);
  };

  return (
    <>
      <MailAliasCard
        accountManagementOn={accountManagementOn}
        mail={mail}
        onSubmit={(data) => submitMailPropertiesChange(data)}
        onIdentifierCheck={(type, mail) => searchIdentifier(type, mail)}
      />
    </>
  );
};

MailAlias.propTypes = {
  mail: PropTypes.object.isRequired,
  setMail: PropTypes.func.isRequired,
  identity: PropTypes.object.isRequired,
};

export default withApiCapabilities(
  withRouter(
    connect(() => ({}), {
      updateMailProperties,
      searchMailPropertiesIdentifier,
    })(MailAlias)
  )
);
