import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Icon, Segment, Header, Message, Grid } from "semantic-ui-react";
import {
  isApiCallLoading,
  selectAccountManagementOn,
  selectCernAccountProvider,
} from "../../selectors";
import { withApiCapabilities } from "../../wrappers";
import ReassignAccountModal from "./ReassignAccountModal";
import ChangePasswordModal from "./passwordManagement/ChangePasswordModal";
import ResetPasswordModal from "./passwordManagement/ResetPasswordModal";
import { errorAction, types as at } from "@authzsvc/api-lib";
import { isSecondaryOrService, isService } from "../../utils";
import {
  changePassword,
  resetPassword,
  setInitialPassword,
} from "../../actions/api";
import { showErrorNotification } from "../../utils";

const AccountEditActions = ({
  identity,
  identityOwner,
  account,
  loading,
  accountManagementOn,
  cernAccountProvider,
  onUpdateIdentity, // prop function called after identity has been updated
  // redux Actions
  setInitialPassword,
  changePassword,
  resetPassword,
  userToken,
}) => {
  const onResetPassword = async (newPassword, account) => {
    const resetResp = resetPassword(account.id, newPassword);
    if (resetResp.type === errorAction(at.RESET_PASSWORD)) {
      showErrorNotification(
        resetResp.payload.title,
        resetResp.payload.data.message
      );
    }
    return resetResp;
  };

  const onSetInitialPassword = async (newPassword, account) => {
    const setResp = await setInitialPassword(account.id, newPassword);
    if (setResp.type === errorAction(at.SET_INITIAL_PASSWORD)) {
      showErrorNotification(
        setResp.payload.title,
        setResp.payload.data.message
      );
    } else {
      onUpdateIdentity(identity.id);
    }
    return setResp;
  };

  const onChangePassword = async (oldPassword, newPassword, account) => {
    const changeResp = await changePassword(
      account.id,
      oldPassword,
      newPassword
    );
    if (changeResp.type === errorAction(at.CHANGE_PASSWORD)) {
      showErrorNotification(
        changeResp.payload.title,
        changeResp.payload.data.message
      );
    }
    return changeResp;
  };

  const actionsPermitted = () => {
    // If it's a CERN secondary/service account owned by the user, or the person's own primary
    if (
      cernAccountProvider &&
      account &&
      identity &&
      ((isSecondaryOrService(identity) &&
        identityOwner.upn === userToken.sub) ||
        identity.upn === userToken.sub) &&
      account.accountProviderId === cernAccountProvider.id
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {
        <Segment basic color="blue">
          <Header as="h3">
            <span>
              <Icon name="setting" />
              Actions
            </span>
          </Header>
          {actionsPermitted() ? (
            <>
              <Grid columns={1}>
                {/* If not blocked, can update password */}
                {!identity.blocked && !account.blocked && (
                  <>
                    {/* Can only reset secondary and service accounts */}
                    {isSecondaryOrService(identity) && (
                      <Grid.Row>
                        <ResetPasswordModal
                          loading={loading}
                          onSubmit={(newPassword) =>
                            onResetPassword(newPassword, account)
                          }
                          identity={identity}
                          title="Reset password"
                        />
                      </Grid.Row>
                    )}
                    <Grid.Row>
                      {/* Can change primary/secondar/service passwords */}
                      <ChangePasswordModal
                        loading={loading}
                        onSubmit={(oldPassword, newPassword) =>
                          onChangePassword(oldPassword, newPassword, account)
                        }
                        identity={identity}
                        title="Change password"
                      />
                    </Grid.Row>
                  </>
                )}
                {/* If not blocked, can set initial password for secondary/service if not yet activated */}
                {!identity.blocked &&
                  account.blocked &&
                  account.blockingReason ===
                    "Account pending initial activation" &&
                  isSecondaryOrService(identity) && (
                    <Grid.Row>
                      <ResetPasswordModal
                        loading={loading}
                        onSubmit={(newPassword) =>
                          onSetInitialPassword(newPassword, account)
                        }
                        identity={identity}
                        title="Set an initial password"
                      />
                    </Grid.Row>
                  )}
                {/* If account management on, can reassign service identities */}
                {accountManagementOn && (
                  <Grid.Row>
                    {isService(identity) && (
                      <ReassignAccountModal identity={identity} />
                    )}
                  </Grid.Row>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Message>
                <Message.Header>No Actions Available</Message.Header>
                <p>
                  This is not a CERN Account, or you are not the account owner.
                </p>
              </Message>
            </>
          )}
        </Segment>
      }
    </>
  );
};

AccountEditActions.propTypes = {
  identity: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  cernAccountProvider: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  accountManagementOn: PropTypes.bool.isRequired,
  userToken: PropTypes.object.isRequired,
  onUpdateIdentity: PropTypes.func.isRequired,
};

export default withApiCapabilities(
  connect(
    (state, ownProps) => ({
      accountManagementOn: selectAccountManagementOn(ownProps.capabilities),
      loading:
        isApiCallLoading(state, at.SET_INITIAL_PASSWORD) ||
        isApiCallLoading(state, at.CHANGE_PASSWORD) ||
        isApiCallLoading(state, at.RESET_PASSWORD),
      cernAccountProvider: selectCernAccountProvider(state),
      userToken: state.keycloak.userToken,
    }),
    {
      changePassword,
      resetPassword,
      setInitialPassword,
    }
  )(AccountEditActions)
);
