import { types, apiActionCreator } from "@authzsvc/api-lib";

export const getIdentity = () => (dispatch) => {
  dispatch(
    apiActionCreator(types.GET_IDENTITY, {
      queryFilter: {
        field: [
          "upn",
          "source",
          "orcid",
          "ownerId",
          "externalEmail",
          "owner",
          "supervisorId",
          "directResponsibleId",
          "type",
          "displayName",
          "primaryAccountEmail",
          "gid",
          "cernPersonId",
          "uid",
          "computingRulesAccepted",
          "computingRulesValidUntil",
          "computingRulesAcceptedFlag",
          "activeUser",
          "cernClass",
          "endClass",
          "blockingDeadline",
          "expirationDeadline",
        ],
      },
    })
  );
};

export const getIdentityMemberships =
  (identityId, queryFilter) => async (dispatch) =>
    await dispatch(
      apiActionCreator(types.GET_IDENTITY_MEMBERSHIPS, {
        identityId,
        queryFilter,
      })
    );

export const createIdentity = (identity) => async (dispatch) => {
  return await dispatch(apiActionCreator(types.CREATE_IDENTITY, { identity }));
};

export const deleteIdentity = (identityid) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.DELETE_IDENTITY, { identityid })
  );
};

export const getMyGroups = (queryFilter) => async (dispatch) => {
  return await dispatch(apiActionCreator(types.GET_MY_GROUPS, { queryFilter }));
};

export const getMyOwnedIdentities = (ownerId) => (dispatch) =>
  dispatch(apiActionCreator(types.GET_OWNED_IDENTITIES, { ownerId }));

export const resetPassword = (accountId, newPassword) => (dispatch) =>
  dispatch(apiActionCreator(types.RESET_PASSWORD, { accountId, newPassword }));

export const setInitialPassword = (accountId, newPassword) => (dispatch) =>
  dispatch(
    apiActionCreator(types.SET_INITIAL_PASSWORD, { accountId, newPassword })
  );

export const changePassword =
  (accountId, oldPassword, newPassword) => (dispatch) =>
    dispatch(
      apiActionCreator(types.CHANGE_PASSWORD, {
        accountId,
        oldPassword,
        newPassword,
      })
    );

export const changeEdhPassword =
  (accountId, oldPassword, newPassword) => (dispatch) =>
    dispatch(
      apiActionCreator(types.CHANGE_EDHPASSWORD, {
        accountId,
        oldPassword,
        newPassword,
      })
    );

export const createServiceLogin = (ownerId, login) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.CREATE_SERVICE, { ownerId, login })
  );
};

export const createSecondaryLogin = (ownerId, login) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.CREATE_SECONDARY, { ownerId, login })
  );
};

export const getGroupById = (id) => (dispatch) => {
  dispatch(apiActionCreator(types.GET_GROUP_BY_ID, { id: id }));
};

export const getAccounts = () => (dispatch) => {
  dispatch(apiActionCreator(types.GET_CURRENT_IDENTITY_ACCOUNTS));
};

export const getAccountsForIdentity = (identityId) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.GET_ACCOUNTS_FOR_IDENTITY, { identityId })
  );
};

export const getCurrentPrimaryAccount = () => (dispatch) => {
  dispatch(apiActionCreator(types.GET_CURRENT_PRIMARY_ACCOUNT));
};

export const getAccountProviders = () => (dispatch) => {
  dispatch(
    apiActionCreator(types.GET_ACCOUNT_PROVIDERS, {
      queryFilter: {
        field: ["loaValue", "displayName"],
      },
    })
  );
};

export const setPrimaryAccount = (accountId) => (dispatch) =>
  dispatch(apiActionCreator(types.SET_PRIMARY_ACCOUNT, { accountId }));

export const getAccountById = (id) => (dispatch) => {
  dispatch(apiActionCreator(types.GET_ACCOUNT_BY_ID, { id: id }));
};

export const getMfaSettingsForAccount = (id) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.GET_MFA_SETTINGS_FOR_ACCOUNT, {
      id,
    })
  );
};

export const setMfaSettingsForAccount = (id, data) => async (dispatch) =>
  dispatch(
    apiActionCreator(types.SET_MFA_SETTINGS_FOR_ACCOUNT, {
      id,
      data,
    })
  );

export const getAllMfaCredentials = (id) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.GET_ALL_MFA_CREDENTIALS, {
      id: id,
    })
  );
};

export const resetWebAuthnCredentials = (id) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.RESET_WEBAUTHN_CREDENTIALS, {
      id: id,
    })
  );
};

export const resetOTPCredentials = (id) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.RESET_OTP_CREDENTIALS, {
      id: id,
    })
  );
};

export const deleteWebAuthnCredentials = (id) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.DELETE_WEBAUTHN_CREDENTIALS, {
      id: id,
    })
  );
};

export const deleteOTPCredentials = (id) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.DELETE_OTP_CREDENTIALS, {
      id: id,
    })
  );
};

// Requests
export const getMyRequests = () => (dispatch) => {
  dispatch(apiActionCreator(types.GET_REQUESTS, { queryParams: null }));
};

export const getRequestsToApprove = () => (dispatch) => {
  dispatch(
    apiActionCreator(types.GET_REQUESTS_TO_APPROVE, { queryParams: null })
  );
};

export const cancelRequest = (id) => async (dispatch) => {
  return await dispatch(apiActionCreator(types.CANCEL_REQUEST, { id: id }));
};

export const denyRequest = (id, reason) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.DENY_REQUEST, {
      id: id,
      reason: reason,
    })
  );
};

export const approveRequest =
  (id, reason, approvalToken) => async (dispatch) => {
    return await dispatch(
      apiActionCreator(types.APPROVE_REQUEST, {
        id: id,
        reason: reason,
        approvalToken: approvalToken,
      })
    );
  };

export const searchForPerson =
  (searchTerm, filterFields = null) =>
  async (dispatch) => {
    return await dispatch(
      apiActionCreator(types.SEARCH_IDENTITY, {
        name: searchTerm,
        identityType: "Person",
        filterFields: filterFields,
      })
    );
  };

export const searchForIdentity = (name) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.SEARCH_IDENTITY, {
      name: name,
    })
  );
};

export const searchForGroup = (queryFilter) => async (dispatch) => {
  return await dispatch(apiActionCreator(types.SEARCH_GROUP, { queryFilter }));
};

export const searchGroupIdentifier = (groupIdentifiers) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.SEARCH_GROUP_IDENTIFIER, {
      identifierProperty: "groupidentifier",
      groupIdentifiers: groupIdentifiers,
    })
  );
};

export const associateIdentity = (otherId) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.ASSOCIATE_IDENTITY, {
      other: otherId,
    })
  );
};

export const associateSocialIdentity = (socialIdToken) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.ASSOCIATE_SOCIAL_IDENTITY, {
      token: socialIdToken,
    })
  );
};

export const deleteAccountFromIdentity = (accountId) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.DELETE_ACCOUNT_FROM_IDENTITY, {
      accountid: accountId,
    })
  );
};

export const getIdentityById = (id) => async (dispatch) =>
  await dispatch(apiActionCreator(types.GET_IDENTITY_BY_ID, { id: id }));

export const logout = () => async (dispatch) => {
  return await dispatch(apiActionCreator(types.LOGOUT));
};

export const patchIdentity = (id, body) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.PATCH_IDENTITY, { id: id, body: body })
  );
};

export const updateExternalEmail = (id, email) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.UPDATE_EXTERNAL_EMAIL, { id: id, email: email })
  );
};

export const resolveIdentity = (id) => async (dispatch) =>
  await dispatch(apiActionCreator(types.RESOLVE_IDENTITY, { identityid: id }));

export const getAccountScopes = (queryFilter) => async (dispatch) =>
  await dispatch(
    apiActionCreator(types.GET_ACCOUNT_SCOPES, { queryFilter: queryFilter })
  );

export const getScopesForAccount = (id) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.GET_SCOPES_FOR_ACCOUNT, {
      id: id,
    })
  );
};

export const addAccountScope = (accountId, scopeId) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.ADD_ACCOUNT_TO_SCOPE, {
      accountId: accountId,
      scopeId: scopeId,
    })
  );
};

export const deleteAccountScope = (accountId, scopeId) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.REMOVE_ACCOUNT_FROM_SCOPE, {
      accountId: accountId,
      scopeId: scopeId,
    })
  );
};

export const getMailProperties = (identityId) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.GET_MAIL_PROPERTIES, { identityId })
  );
};

export const updateMailProperties = (identityId, body) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.UPDATE_MAIL_PROPERTIES, {
      identityId: identityId,
      body: body,
    })
  );
};

export const setMailProperties = (identityId, newAlias) => async (dispatch) => {
  return await dispatch(
    apiActionCreator(types.SET_MAIL_PROPERTIES, {
      identityId: identityId,
      newAlias: newAlias,
    })
  );
};

export const searchMailPropertiesIdentifier =
  (identifierName, mailPropertiesIdentifiers) => async (dispatch) => {
    return await dispatch(
      apiActionCreator(types.SEARCH_MAIL_PROPERTIES_IDENTIFIER, {
        identifierName: identifierName,
        identifierValues: mailPropertiesIdentifiers,
      })
    );
  };

export const transferIdentity =
  (identityId, otherIdentityId) => async (dispatch) => {
    return await dispatch(
      apiActionCreator(types.TRANSFER_IDENTITY, {
        identityId: identityId,
        otherIdentityId: otherIdentityId,
      })
    );
  };

export const getAllUserRolesForApp = (applicationId) => async (dispatch) =>
  await dispatch(
    apiActionCreator(types.GET_ALL_MY_APP_ROLES, { applicationId })
  );
