import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  Header,
  Icon,
  Segment,
  Table,
  Grid,
} from "semantic-ui-react";
import { withRouter } from "react-router";
import {
  getAccountsForIdentity,
  getIdentityById,
  getMailProperties,
} from "../../actions/api";
import {
  isApiCallLoading,
  selectAccountManagementOn,
  selectCernAccountProvider,
} from "../../selectors";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { successAction, types as at } from "@authzsvc/api-lib";
import MailAlias from "./mailSettings/MailAlias";
import { setBreadcrumbs } from "../../actions/breadcrumbs";
import GidInfo from "../common/GidInfo";
import GidAssignmentModal from "../common/GidAssignmentModal";
import PlaceholderLineLoader from "../common/PlaceholderLineLoader";
import styles from "./AccountEdit.module.scss";
import { withApiCapabilities } from "../../wrappers";
import BlockedForSecurityIssues from "../common/BlockedForSecurityIssues";
import BlockedWarning from "../common/BlockedWarning";
import AccountEditActions from "./AccountEditActions";
import { isSecondary, isService } from "../../utils";
import { useIsDesktop } from "../../hooks";

function AccountEdit({
  // Actions
  getIdentityById,
  getAccountsForIdentity,
  getMailProperties,
  setBreadcrumbs,
  // Selectors
  loading,
  cernAccountProvider,
  // Props
  breadcrumbs,
  accountManagementOn,
  match,
}) {
  const [identity, setIdentity] = useState({});
  const [identityOwner, setIdentityOwner] = useState({});
  const [account, setAccount] = useState({});
  const [mail, setMail] = useState({});
  const isDesktop = useIsDesktop();

  useEffect(() => {
    setBreadcrumbs(breadcrumbs);
  }, [breadcrumbs, setBreadcrumbs]);

  const getAndSetIdentityAndCernAccount = async (id) => {
    const identity_req = await getIdentityById(id);
    if (identity_req && identity_req.payload.data) {
      setIdentity(identity_req.payload.data);
    }

    const account_req = await getAccountsForIdentity(id);
    if (account_req && account_req.payload.data && cernAccountProvider) {
      setAccount(
        account_req.payload.data.find(
          (ac) => ac.accountProviderId === cernAccountProvider.id
        )
      );
    }
  };

  const onMount = useCallback(async () => {
    const identity_req = await getIdentityById(match.params.id);
    if (identity_req && identity_req.payload.data) {
      setIdentity(identity_req.payload.data);
      if (identity_req.payload.data.ownerId) {
        const identity_owner_req = await getIdentityById(
          identity_req.payload.data.ownerId
        );
        if (identity_owner_req) {
          setIdentityOwner(identity_owner_req.payload.data);
        }
      }

      // Secondary accounts don't have mail settings to edit
      if (!isSecondary(identity_req.payload.data)) {
        if (accountManagementOn) {
          let mailResponse = await getMailProperties(match.params.id);
          if (mailResponse.type === successAction(at.GET_MAIL_PROPERTIES)) {
            setMail(mailResponse.payload.data);
          }
        }
      }
    }
    const account_req = await getAccountsForIdentity(match.params.id);
    if (account_req && account_req.payload.data && cernAccountProvider) {
      setAccount(
        account_req.payload.data.find(
          (ac) => ac.accountProviderId === cernAccountProvider.id
        )
      );
    }
  }, [
    getIdentityById,
    getAccountsForIdentity,
    match.params.id,
    setIdentity,
    cernAccountProvider,
    getMailProperties,
    accountManagementOn,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <>
      {loading && (
        <>
          <PlaceholderLineLoader count={2} />
          <br />
        </>
      )}
      {!loading && (
        <Container>
          {identity.securityIssues && (
            <BlockedForSecurityIssues identity={identity} />
          )}
          <Segment>
            <Grid columns={isDesktop ? 2 : 1}>
              <Grid.Column width={isDesktop ? 12 : 16}>
                <Segment basic color="blue">
                  <Header as="h3">
                    <span>
                      <Icon className={styles.userIcon} name="user" />
                      {account ? "CERN" : "External "} account: {identity.upn}{" "}
                      {identity.blocked && <BlockedWarning />}
                    </span>
                  </Header>
                  <Table definition celled>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>Display Name</Table.Cell>
                        <Table.Cell>{identity.displayName}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Username</Table.Cell>
                        <Table.Cell>{identity.upn}</Table.Cell>
                      </Table.Row>
                      {(isSecondary(identity) || isService(identity)) && (
                        <Table.Row>
                          <Table.Cell>Account Owner</Table.Cell>
                          <Table.Cell>{identityOwner.upn} </Table.Cell>
                        </Table.Row>
                      )}
                      <Table.Row>
                        <Table.Cell>Blocked Status</Table.Cell>
                        <Table.Cell>
                          {account.blocked ? (
                            <>
                              <b className={styles.blocked}>
                                <Icon name="ban" />
                                Blocked &nbsp;
                              </b>
                              {account.blockingReason && (
                                <b>{account.blockingReason}</b>
                              )}
                            </>
                          ) : (
                            <>
                              <b className={styles.active}>
                                <Icon name="check" />
                                Enabled
                              </b>
                            </>
                          )}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Description</Table.Cell>
                        <Table.Cell>{identity.description}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell collapsing>
                          <GidInfo />
                        </Table.Cell>
                        <Table.Cell>
                          <GidAssignmentModal identity={identity} readOnly />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Unix Identifier</Table.Cell>
                        <Table.Cell>{identity.uid}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Segment>
              </Grid.Column>
              <Grid.Column floated="right" width={isDesktop ? 4 : 16}>
                <AccountEditActions
                  identity={identity}
                  identityOwner={identityOwner}
                  account={account}
                  accountManagementOn={accountManagementOn}
                  cernAccountProvider={cernAccountProvider}
                  onUpdateIdentity={getAndSetIdentityAndCernAccount}
                />
              </Grid.Column>
            </Grid>
          </Segment>
          {accountManagementOn &&
            mail &&
            cernAccountProvider &&
            account &&
            !isSecondary(identity) &&
            account.accountProviderId === cernAccountProvider.id && (
              <MailAlias mail={mail} setMail={setMail} identity={identity} />
            )}
        </Container>
      )}
    </>
  );
}

AccountEdit.propTypes = {
  // redux actions
  getIdentityById: PropTypes.func.isRequired,
  getAccountsForIdentity: PropTypes.func.isRequired,
  getMailProperties: PropTypes.func.isRequired,
  // props
  loading: PropTypes.bool.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  accountManagementOn: PropTypes.bool.isRequired,
  cernAccountProvider: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withApiCapabilities(
  withRouter(
    connect(
      (state, ownProps) => ({
        accountManagementOn: selectAccountManagementOn(ownProps.capabilities),
        cernAccountProvider: selectCernAccountProvider(state),
        loading:
          isApiCallLoading(state, at.GET_IDENTITY_BY_ID) ||
          isApiCallLoading(state, at.GET_ACCOUNTS_FOR_IDENTITY) ||
          isApiCallLoading(state, at.GET_MAIL_PROPERTIES),
      }),
      {
        getIdentityById,
        getAccountsForIdentity,
        getMailProperties,
        setBreadcrumbs,
      }
    )(AccountEdit)
  )
);
