import React, { useState } from "react";
import PropTypes from "prop-types";
import { Icon, Modal, Button, Segment, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { searchForPerson, transferIdentity } from "../../actions/api";
import { AutoComplete, Table, Tooltip } from "antd";
import _ from "lodash";

import styles from "./ReassignAccountModal.module.scss";
import { selectAccountManagementOn } from "../../selectors";
import { withApiCapabilities } from "../../wrappers";
import { errorAction, types as at } from "@authzsvc/api-lib";
import { showErrorNotification } from "../../utils";

const ReassignAccountModal = ({
  identity,
  accountManagementOn,
  // API actions
  searchForPerson,
  transferIdentity,
}) => {
  const [open, setOpen] = useState(false);
  const [searchIsLoading, setSearchIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [newOwner, setNewOwner] = useState(null);

  const close = (e) => {
    setOpen(false);
    setNewOwner(null);
    setSearchIsLoading(false);
    setDataSource([]);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "displayName",
    },
    {
      title: "Login",
      dataIndex: "upn",
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (_, record) => (
        <Button positive icon onClick={() => handleSelect(record)}>
          <Icon name="checkmark" />
        </Button>
      ),
    },
  ];

  const handleSelect = async (record) => {
    setNewOwner(record);
    setDataSource(dataSource.filter((a) => a.personId === record.personId));
  };

  const handleTransfer = async () => {
    const resp = transferIdentity(identity.id, newOwner.id);
    if (resp.type === errorAction(at.TRANSFER_IDENTITY)) {
      showErrorNotification(resp.payload.title, resp.payload.data.message);
    }
    close();
  };

  const debouncedSearch = _.debounce((value) => {
    setNewOwner(null);
    if (value.length >= 2) {
      setSearchIsLoading(true);
      searchForPerson(value).then((action) => {
        setDataSource(
          action.payload.filter(
            (a) => a.personId != null && a.type === "Person"
          )
        );
        setSearchIsLoading(false);
      });
    }
  }, 500);

  return (
    <>
      {accountManagementOn && (
        <Modal
          size="small"
          open={open}
          onSubmit={(event) => event.preventDefault()}
          onClose={close}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          trigger={
            <Tooltip title="Transfer service login">
              <Button
                icon
                fluid
                labelPosition="left"
                primary
                onClick={() => setOpen(true)}
              >
                <Icon name="paste" /> Reassign Account
              </Button>
            </Tooltip>
          }
        >
          <Modal.Header>
            <Icon name="user" />
            Transfer service login
          </Modal.Header>
          <Modal.Content image>
            <Segment basic>
              <p>
                Transfer this service login (<span>{identity.upn}</span>), to
                another CERN User. Type at least 2 characters of the identity's
                email address, main login or name (first name or last name).
              </p>
              <AutoComplete
                style={{ minWidth: "100%" }}
                onChange={debouncedSearch}
                onSearch={debouncedSearch}
                onSubmit={(event) => event.preventDefault()}
                enterButton
                placeholder="Main login, mail or name (first name or last name)"
              />
              <Table
                className={styles.tableContent}
                style={{ marginTop: "10px" }}
                loading={searchIsLoading}
                rowKey="id"
                pagination={{
                  pageSize: 10,
                }}
                columns={columns}
                dataSource={dataSource}
              />
              {newOwner && (
                <Message positive>
                  <Message.Header>New owner selected</Message.Header>
                  Confirm to transfer <b>{identity.upn}</b> to{" "}
                  <b>{newOwner.displayName}</b>
                </Message>
              )}
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={close}
              negative
              icon="cancel"
              labelPosition="left"
              content="Cancel"
            />
            <Button
              onClick={handleTransfer}
              disabled={!newOwner}
              positive
              labelPosition="left"
              icon="checkmark"
              content="Confirm"
            />
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

ReassignAccountModal.propTypes = {
  identity: PropTypes.object.isRequired,
};

export default withApiCapabilities(
  connect(
    (state, ownProps) => ({
      accountManagementOn: selectAccountManagementOn(ownProps.capabilities),
    }),
    {
      searchForPerson,
      transferIdentity,
    }
  )(ReassignAccountModal)
);
