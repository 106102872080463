import React from "react";
import PropTypes from "prop-types";
import { getMailProperties, updateMailProperties } from "../../../actions/api";
import { UseExternalMailBoxModal } from "@authzsvc/common-react-components";
import { connect } from "react-redux";
import { errorAction, types as at } from "@authzsvc/api-lib";
import { showErrorNotification } from "../../../utils";

const UseExternalMailBox = ({
  identity,
  updateMailProperties,
  getMailProperties,
}) => {
  const fetchMailProperties = async () => {
    return await getMailProperties(identity.id);
  };

  const submit = async (mail) => {
    const resp = await updateMailProperties(identity.id, mail);
    if (resp.type === errorAction(at.UPDATE_MAIL_PROPERTIES)) {
      showErrorNotification(resp.payload.title, resp.payload.data.message);
    }
    return resp;
  };

  return (
    <UseExternalMailBoxModal
      identity={identity}
      onUpdate={(mail) => submit(mail)}
      onMailPropertiesFetch={() => fetchMailProperties()}
    />
  );
};

UseExternalMailBox.propTypes = {
  identity: PropTypes.object.isRequired,
  updateMailProperties: PropTypes.func.isRequired,
  getMailProperties: PropTypes.func.isRequired,
};

export default connect(() => ({}), {
  getMailProperties,
  updateMailProperties,
})(UseExternalMailBox);
