import React, { useEffect, useState, useCallback } from "react";
import { Icon, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { patchIdentity } from "../../actions/api";
import "../../assets/Identity.css";
import { decode } from "jsonwebtoken";
import { orcidClientId } from "../../config.js";
import orcidLogo from "../../assets/orcid_logo.png";
import { errorAction, types as at } from "@authzsvc/api-lib";
import { showErrorNotification } from "../../utils.js";

const Orcid = ({
  // redux actions
  patchIdentity,
  // props
  identity,
}) => {
  const ORCID_ACTIONS = {
    REMOVE: "remove",
    ADD: "add",
  };

  const [orcidId, setOrcidId] = useState(null);

  const editOrcid = useCallback(
    async (id, value, op) => {
      console.log("Setting ORCID to ", value);
      setOrcidId(value);
      const resp = await patchIdentity(id, [
        { value: value, op: op, path: "/orcid" },
      ]);
      if (resp.type === errorAction(at.PATCH_IDENTITY)) {
        showErrorNotification(resp.payload.title, resp.payload.data.message);
      }
    },
    [setOrcidId, patchIdentity]
  );

  // We may already have the ORCID as part of the identity object
  useEffect(() => {
    if (identity.orcid) {
      setOrcidId(identity.orcid);
    }
  }, [identity.orcid]);

  // If we are coming back from ORCID, link the ORCID ID and save it to the identity
  useEffect(() => {
    if (
      window.location.hash &&
      window.location.pathname === "/link-orcid/" &&
      identity &&
      !identity.orcid &&
      !orcidId
    ) {
      let id_token_encoded = new URLSearchParams(window.location.hash).get(
        "id_token"
      );
      // Remove window hash to avoid reprocessing the ORCID if the page is reloaded
      var noHashURL = window.location.href.replace(/\/link-orcid\/#.*$/, "");
      window.history.replaceState("", document.title, noHashURL);
      // If we have the id_token, extract the ID and save it
      if (id_token_encoded) {
        let id_token = decode(id_token_encoded);
        editOrcid(identity.id, id_token["sub"], ORCID_ACTIONS.ADD);
      }
    }
  }, [identity, orcidId, ORCID_ACTIONS.ADD, editOrcid]);

  return (
    <>
      {orcidId ? (
        <>
          <a href={"https://orcid.org/" + orcidId}>
            <img alt="ORCID Logo" src={orcidLogo} width="16" height="16" />{" "}
            https://orcid.org/{orcidId}
          </a>
          <Button
            onClick={() => editOrcid(identity.id, "", ORCID_ACTIONS.REMOVE)}
            floated="right"
            color="red"
            icon
          >
            <Icon name="unlink" />
          </Button>
        </>
      ) : (
        <a
          href={
            "https://orcid.org/oauth/authorize?client_id=" +
            orcidClientId +
            "&response_type=token&scope=openid&redirect_uri=" +
            window.location.href +
            "link-orcid/"
          }
        >
          <img alt="ORCID Logo" src={orcidLogo} width="16" height="16" /> Create
          or connect your ORCID iD
        </a>
      )}
    </>
  );
};

export default withRouter(connect((state) => ({}), { patchIdentity })(Orcid));
